<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
    >

      <template #overlay>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
          <p class="mt-1">
            {{ $t('Fetching Data') }}...
          </p>
        </div>

      </template>
      <b-tabs>
        <b-tab
          title="Performance"
        >
          <div
            class="d-flex flex-column align-items-center"
            style="width: auto; height: 75vh; overflow-y: scroll;"
          >
            <div
              class="mb-1 d-flex justify-content-center"
              style="font-size: 1.9rem; font-weight: 500;"
            >
              {{ $t("Update Performance") }}
              <!-- {{ performance }} {{ user }} {{ roleUser }} {{ settingStatus }} -->
              <!-- {{ subsidiaryID }} -->
              <br>
            </div>
            <b-row class="container">
              <b-col>

                <div
                  v-if="roleUser === 2"
                >
                  <!-- JD NON-EXEC -->
                  <div v-if="settingStatus === 0">
                    <!-- JD Non-Exec <br>
                  User ID:{{ this.user }} <br>
                  Status:{{ settingStatus }} <br>
                  JD ID: {{ this.jdID }} -->

                    <div
                      class="saj-header p-1"
                      style="text-align: center;"
                    >
                      {{ $t("JOB DESCRIPTION") }}
                    </div>
                    <!-- main task -->
                    <b-row
                      class="p-1"
                      style="background: rgb(247, 247, 252)"
                    >
                      <b-row
                        class="
                    saj-subtitle
                  "
                      >
                        <b-col>
                          <b-row>
                            <b-col
                              style=" white-space: pre-line;
                            vertical-align: top;
                            text-align: left;"
                            > {{ $t("Main Task") }}: {{ $t(mainTaskJD) }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <span>
                                {{ $t("Self Score") }}: {{ $t(mainTaskScoreJD) }}/6</span></b-col>
                          </b-row>
                          <b-row class="">
                            <b-col
                              class="d-flex flex-row align-items-center"
                              style="
                          font-weight: 600;
                        "
                            >
                              {{ $t("Final Score") }}:

                              <b-form-rating
                                v-model="valueJD1"
                                no-border
                                show-value
                                inline
                                color="#DFB300"
                                stars="6"
                                size="lg"
                                class="ml-1"
                                :show-clear="valueJD1 !== null && valueJD1 !== 0"
                                @change="valueJD1 === null ? valueJD1 = 0 : valueJD1"
                              />
                              <span class="ml-1">
                                <h5 v-if="valueJD1 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                                <h5 v-else-if="valueJD1 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                                <h5 v-else-if="valueJD1 == '3'">
                                  {{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}
                                </h5>
                                <h5 v-else-if="valueJD1 == '4'">
                                  {{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}
                                </h5>
                                <h5 v-else-if="valueJD1 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                                <h5 v-else-if="valueJD1 == '6'">
                                  {{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}
                                </h5>
                              </span>
                            </b-col>
                          </b-row>
                        </b-col>

                      </b-row>
                    </b-row>

                    <!-- Additional Task -->
                    <b-row
                      class="mt-1 p-1"
                      style="background: rgb(247, 247, 252)"
                    >
                      <b-row
                        class="
                    saj-subtitle
                  "
                      >
                        <b-col>
                          <b-row>
                            <b-col
                              style=" white-space: pre-line;
                            vertical-align: top;
                            text-align: left;"
                            > {{ $t("Additional Task") }}: {{ $t(sideTaskJD) }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <span>
                                {{ $t("Self Score") }}: {{ $t(sideTaskScoreJD) }}/6</span></b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="d-flex flex-row align-items-center"
                              style="
                          font-weight: 600;
                        "
                            >
                              {{ $t("Final Score") }}:

                              <b-form-rating
                                v-model="valueJD2"
                                no-border
                                show-value
                                inline
                                color="#DFB300"
                                stars="6"
                                size="lg"
                                class="ml-1"
                                :show-clear="valueJD2 !== null && valueJD2 !== 0"
                                @change="valueJD2 === null ? valueJD2 = 0 : valueJD2"
                              />
                              <span class="ml-1">
                                <h5 v-if="valueJD2 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                                <h5 v-else-if="valueJD2 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                                <h5 v-else-if="valueJD2 == '3'">
                                  {{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}
                                </h5>
                                <h5 v-else-if="valueJD2 == '4'">
                                  {{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}
                                </h5>
                                <h5 v-else-if="valueJD2 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                                <h5 v-else-if="valueJD2 == '6'">
                                  {{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}
                                </h5>
                              </span>
                            </b-col></b-row></b-col>
                      </b-row>
                    </b-row>
                  </div>
                  <!-- JD NON-EXEC END -->

                  <!-- KRA NON-EXEC -->
                  <div
                    v-if="settingStatus === 1"
                  >
                    <div
                      class="saj-header p-1"
                      style="text-align: center;"
                    >
                      {{ $t("MAIN TASK") }}
                    </div>
                    <!-- main task kra non exec-->
                    <div
                      v-if="mainKraNonExec.length === 0"
                      class="saj-subtitle row p-1 d-flex justify-content-center"
                      style="font-style: italic; background: rgb(247, 247, 252); text-align: center"
                    >
                      {{ $t("No data available") }}
                    </div>
                    <div v-else>
                      <b-row
                        v-for="(kramain, e) in mainKraNonExec"
                        :id="mainKraNonExec.id"
                        :key="'e' + e"
                        ref="row"
                        class="p-1"
                        style="background: rgb(247, 247, 252)"
                      >
                        <b-row
                          class="
                    saj-subtitle
                  "
                        >
                          <b-col>
                            <b-row>
                              <b-col> {{ $t("Main Task") }}: {{ kramain.main_task_field }} </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <span> {{ $t("Self Score") }}: {{ kramain.marks }}/6</span></b-col>
                            </b-row>
                            <b-row>
                              <b-col
                                cols=""
                                class="d-flex flex-row align-items-center"
                                style="
                          font-weight: 600;
                        "
                              >
                                {{ $t("Final Score") }}:

                                <b-form-rating
                                  v-model="kramain.valueMainKra"
                                  no-border
                                  show-value
                                  inline
                                  color="#DFB300"
                                  stars="6"
                                  size="lg"
                                  class="ml-1"
                                  :show-clear="kramain.valueMainKra !== null && kramain.valueMainKra !== 0"
                                  @change="kramain.valueMainKra === null ? kramain.valueMainKra = 0 : kramain.valueMainKra"
                                />
                                <span class="ml-1">
                                  <h5 v-if="kramain.valueMainKra == '1'">
                                    {{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kramain.valueMainKra == '2'">
                                    {{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kramain.valueMainKra == '3'">
                                    {{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kramain.valueMainKra == '4'">
                                    {{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kramain.valueMainKra == '5'">
                                    {{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kramain.valueMainKra == '6'">
                                    {{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}
                                  </h5>
                                </span>
                              </b-col></b-row>
                          </b-col>
                        </b-row>
                      </b-row>
                    </div>

                    <!-- main task kra END-->
                    <div
                      class="saj-header p-1"
                      style="text-align: center;"
                    >
                      {{ $t("ADDITIONAL TASK") }}
                    </div>
                    <!-- additional task kra -->
                    <div
                      v-if="sideKraNonExec.length === 0"
                      class="saj-subtitle p-1 row d-flex justify-content-center"
                      style="font-style: italic; background: rgb(247, 247, 252); text-align: center"
                    >
                      {{ $t("No data available") }}
                    </div>
                    <div v-else>
                      <b-row
                        v-for="(kraside, f) in sideKraNonExec"
                        :id="sideKraNonExec.id"
                        :key="'f' + f"
                        ref="row"
                        class="p-1"
                        style="background: rgb(247, 247, 252)"
                      >
                        <b-row
                          class="
                    saj-subtitle
                  "
                        >
                          <b-col>
                            <b-row>
                              <b-col>
                                {{ $t("Additional Task") }}: {{ $t(kraside.side_task_summary) }}
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <span> {{ $t("Self Score") }}: {{ $t(kraside.marksSide) }}/6</span></b-col>
                            </b-row>
                            <b-row>
                              <b-col
                                cols=""
                                class="d-flex flex-row align-items-center"
                                style="
                          font-weight: 600;
                        "
                              >
                                {{ $t("Final Score") }}:

                                <b-form-rating
                                  v-model="kraside.valueSideKra"
                                  no-border
                                  show-value
                                  inline
                                  color="#DFB300"
                                  stars="6"
                                  size="lg"
                                  class="ml-1"
                                  :show-clear="kraside.valueSideKra !== null && kraside.valueSideKra !== 0"
                                  @change="kraside.valueSideKra === null ? kraside.valueSideKra = 0 : kraside.valueSideKra"
                                />
                                <span class="ml-1">
                                  <h5 v-if="kraside.valueSideKra == '1'">
                                    {{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kraside.valueSideKra == '2'">
                                    {{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kraside.valueSideKra == '3'">
                                    {{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kraside.valueSideKra == '4'">
                                    {{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kraside.valueSideKra == '5'">
                                    {{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kraside.valueSideKra == '6'">
                                    {{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}
                                  </h5>
                                </span>
                              </b-col></b-row>
                          </b-col>
                        </b-row>
                      </b-row>
                    </div>

                    <!-- additional task kra END-->
                  </div>
                  <!-- KRA NON-EXEC END -->
                </div>
                <!-- KRA EXEC -->
                <div v-else>
                  <div
                    class="saj-header p-1"
                    style="text-align: center;"
                  >
                    {{ $t("KEY RESULT AREA") }}
                  </div>
                  <!-- main task kra exec-->
                  <div
                    v-if="mainKraExec.length === 0"
                    class="saj-subtitle p-1 d-flex justify-content-center"
                    style="font-style: italic; background: rgb(247, 247, 252); text-align: center; margin-left: auto"
                  >
                    {{ $t("No data available") }}
                  </div>
                  <div v-else>
                    <!-- {{ mainKraExec}}adfa -->
                    <div
                      v-for="(kramainexec, e) in mainKraExec"
                      :id="kramainexec.objective_id"
                      :key="'e' + e"
                      class="p-1"
                      style="background: rgb(247, 247, 252)"
                    >
                      <!-- {{ kramainexec }} ami -->
                      <div
                        class="saj-subtitle"
                      >
                        <div class="d-flex flex-column">
                          <div>
                            <b-col> {{ $t("Objective name") }}: {{ kramainexec.objective_name }} </b-col>
                          </div>
                          <!-- <template > -->
                          <div
                            v-for="(kra, i) in kramainexec.key_result_area"
                            :key="'e' + i"
                          >
                            <b-col>
                              <div> {{ $t("KRA name") }}: {{ kra.kra_name }} </div>
                              <div> {{ $t("Self Score") }}: {{ kra.rating }}/6</div>
                              <div
                                class="d-flex align-items-center"
                                style=""
                              >
                                {{ $t("Final Score") }}:

                                <b-form-rating
                                  v-model="kra.manager_rating"
                                  no-border
                                  show-value
                                  inline
                                  color="#DFB300"
                                  stars="6"
                                  size="lg"
                                  class="ml-1"
                                  :show-clear="kra.manager_rating !== null && kra.manager_rating !== 0"
                                  @change="kra.manager_rating === null ? kra.manager_rating = 0 : kra.manager_rating"
                                />
                                <span class="ml-1">
                                  <h5 v-if="kra.manager_rating == 1">
                                    {{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kra.manager_rating == 2">
                                    {{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kra.manager_rating == 3">
                                    {{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kra.manager_rating ==4">
                                    {{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kra.manager_rating == 5">
                                    {{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}
                                  </h5>
                                  <h5 v-else-if="kra.manager_rating == 6">
                                    {{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}
                                  </h5>
                                </span>
                              </div>
                            </b-col>

                          </div>
                          <!-- </template> -->
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
                <!-- KRA EXEC END -->

                <!-- Role:   {{ roleUser }} <br>
            User ID:{{ this.user }} <br> -->

                <div
                  class="saj-header p-1"
                  style="text-align: center;"
                >
                  {{ $t("CORE COMPETENCIES") }}
                </div>
                <!-- Core -->
                <div
                  v-if="existItem2.length === 0"
                  class="saj-subtitle p-1 row d-flex justify-content-center"
                  style="font-style: italic; background: rgb(247, 247, 252); text-align: center"
                >
                  {{ $t("No data available") }}
                </div>
                <div v-else>
                  <b-row
                    v-for="(existitem2, j) in existItem2"
                    :id="existitem2.id"
                    :key="'b' + j"
                    ref="row"
                    class="p-1"
                    style="background: rgb(247, 247, 252)"
                  >
                    <b-row
                      class="saj-subtitle"
                    >
                      <b-col>
                        <b-row>
                          <b-col> {{ $t("Subcore Name") }}: {{ $t(existitem2.subcore_name) }} </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <span>
                              {{ $t("Self Score") }}: {{ $t(existitem2.manager_score) }}/6</span></b-col>
                        </b-row>
                        <b-row><b-col
                          cols=""
                          class="d-flex flex-row align-items-center"
                          style="
                          font-weight: 600;
                        "
                        >
                          {{ $t("Final Score") }}:

                          <b-form-rating
                            v-model="existitem2.value2"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            class="ml-1"
                            :show-clear="existitem2.value2 !== null && existitem2.value2 !== 0"
                            @change="existitem2.value2 === null ? existitem2.value2 = 0 : existitem2.value2"
                          />
                          <span class="ml-1">
                            <h5 v-if="existitem2.value2 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                            <h5 v-else-if="existitem2.value2 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                            <h5 v-else-if="existitem2.value2 == '3'">
                              {{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}
                            </h5>
                            <h5 v-else-if="existitem2.value2 == '4'">
                              {{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}
                            </h5>
                            <h5 v-else-if="existitem2.value2 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                            <h5 v-else-if="existitem2.value2 == '6'">
                              {{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}
                            </h5>
                          </span>
                        </b-col></b-row></b-col>
                    </b-row>
                  </b-row>
                </div>

                <!-- Core End -->
                <div
                  class="saj-header p-1"
                  style="text-align: center;"
                >
                  {{ $t("BEHAVIOUR COMPETENCIES") }}
                </div>
                <!-- Behaviour -->
                <div
                  v-if="existItem3.length === 0"
                  class="saj-subtitle p-1 row d-flex justify-content-center"
                  style="font-style: italic; background: rgb(247, 247, 252); text-align: center"
                >
                  {{ $t("No data available") }}
                </div>
                <div v-else>
                  <b-row
                    v-for="(existitem3, k) in existItem3"
                    :id="existitem3.id"
                    :key="'c' + k"
                    ref="row"
                    class="p-1"
                    style="background: rgb(247, 247, 252)"
                  >
                    <b-row
                      class="
              saj-subtitle
            "
                    >
                      <b-col>
                        <b-row>
                          <b-col> {{ $t("Behaviour Name") }}: {{ $t(existitem3.behaviour_name) }} </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <span>
                              {{ $t("Self Score") }}: {{ $t(existitem3.manager_score) }}/6</span></b-col>
                        </b-row>
                        <b-row>
                          <b-col
                            cols=""
                            class="d-flex flex-row align-items-center"
                            style="
                          font-weight: 600;
                        "
                          >
                            {{ $t("Final Score") }}:

                            <b-form-rating
                              v-model="existitem3.value3"
                              no-border
                              show-value
                              inline
                              color="#DFB300"
                              stars="6"
                              size="lg"
                              class="ml-1"
                              :show-clear="existitem3.value3 !== null && existitem3.value3 !== 0"
                              @change="existitem3.value3 === null ? existitem3.value3 = 0 : existitem3.value3"
                            />
                            <span class="ml-1">
                              <h5 v-if="existitem3.value3 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                              <h5 v-else-if="existitem3.value3 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                              <h5 v-else-if="existitem3.value3 == '3'">
                                {{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}
                              </h5>
                              <h5 v-else-if="existitem3.value3 == '4'">
                                {{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}
                              </h5>
                              <h5 v-else-if="existitem3.value3 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                              <h5 v-else-if="existitem3.value3 == '6'">
                                {{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}
                              </h5>
                            </span>
                          </b-col></b-row>
                      </b-col>
                    </b-row>
                  </b-row>
                </div>

                <!-- Behaviour End-->
                <div
                  class="saj-header p-1"
                  style="text-align: center;"
                >
                  {{ $t("TRAINING") }}
                </div>
                <!-- Training -->
                <div
                  v-if="existItem4.length === 0"
                  class="saj-subtitle p-1 row d-flex justify-content-center"
                  style="font-style: italic; background: rgb(247, 247, 252); text-align: center"
                >
                  {{ $t("No data available") }}
                </div>
                <div v-else>
                  <b-row
                    v-for="(existitem4, l) in existItem4"
                    :id="existitem4.id"
                    :key="'d' + l"
                    ref="row"
                    class="p-1"
                    style="background: rgb(247, 247, 252)"
                  >
                    <b-row
                      class="
              saj-subtitle
            "
                    >
                      <b-col>
                        <b-row>
                          <b-col>
                            {{ $t("Training & Development Name") }}: {{ $t(existitem4.program_name) }}
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <span>
                              {{ $t("Self Score") }}: {{ $t(existitem4.manager_score) }}/6</span></b-col>
                        </b-row>
                        <b-row><b-col
                          cols=""
                          class="d-flex flex-row align-items-center"
                          style="
                          font-weight: 600;
                        "
                        >
                          {{ $t("Final Score") }}:

                          <b-form-rating
                            v-model="existitem4.value4"
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            class="ml-1"
                            :show-clear="existitem4.value4 !== null && existitem4.value4 !== 0"
                            @change="existitem4.value4 === null ? existitem4.value4 = 0 : existitem4.value4"
                          />
                          <span class="ml-1">
                            <h5 v-if="existitem4.value4 == '1'">{{ isEnglish ? ratingPt3[5].definition_en : ratingPt3[5].definition_my }}</h5>
                            <h5 v-else-if="existitem4.value4 == '2'">{{ isEnglish ? ratingPt3[4].definition_en : ratingPt3[4].definition_my }}</h5>
                            <h5 v-else-if="existitem4.value4 == '3'">
                              {{ isEnglish ? ratingPt3[3].definition_en : ratingPt3[3].definition_my }}
                            </h5>
                            <h5 v-else-if="existitem4.value4 == '4'">
                              {{ isEnglish ? ratingPt3[2].definition_en : ratingPt3[2].definition_my }}
                            </h5>
                            <h5 v-else-if="existitem4.value4 == '5'">{{ isEnglish ? ratingPt3[1].definition_en : ratingPt3[1].definition_my }}</h5>
                            <h5 v-else-if="existitem4.value4 == '6'">
                              {{ isEnglish ? ratingPt3[0].definition_en : ratingPt3[0].definition_my }}
                            </h5>
                          </span>
                        </b-col></b-row></b-col>
                    </b-row>
                  </b-row>
                </div>

                <!-- Training End -->
                <!-- <b-col
              class="p-0 m-0"
            > -->
                <div
                  v-if="roles.selectedRole === 1"
                  class="saj-title p-0 mt-2 mb-5 d-flex align-items-center"
                >
                  <div class="d-flex flex-column">{{ $t("PERFORMANCE GRADE") }}   <span style="font-style: italic; font-size: 10px">* For user view only</span></div>
                
                  <b-form-select
                    v-model="selectedGrade"
                    :options="gradeList"
                    :value="gradeList"
                    style="width:30%"
                    size="sm"
                    class="saj-text ml-2"
                  />
                </div>
                <!-- </b-col> -->

                <b-row
                  v-if="isSupervisor === 1"
                  class="saj-title mt-2 mb-5"
                >
                  {{ $t("COMMENTS") }}
                  <b-form-textarea
                    v-model="comment"
                    class="saj-form-text"
                    rows="5"
                  />
                </b-row>

              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <button
                class="btn btn-close m-1 saj-subtitle"
                aria-label="Close modal"
                style="
          color: white;
          background: #ff0000;
          "
                @click="close()"
              >
                {{ $t("Back") }}
              </button>
              <button
                class="btn m-1 saj-subtitle"
                aria-label="submit modal"
                style="
          color: white;
          background: #0b103c !important;
          border-color: #0b103c !important;
        "
                @click="finalScore()"
              >
                {{ $t("Save") }}
              </button>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Comment">
          <div style="width: auto; height: 75vh; overflow-y: scroll;">
            <viewComment :performance="performance" />
          </div>

        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
  <!-- </b-overlay>
  </div> -->

</template>

<script>
import {
  BFormRating,
  // BFormInput,
  BFormTextarea,
  BRow,
  BCol,
  BOverlay,
  BSpinner,
  BFormSelect,
  BTabs,
  BTab,
} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'
import viewComment from '@/views/pages/summary/view_comment.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BFormRating,
    // BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BOverlay,
    BSpinner,
    BFormSelect,
    BTabs,
    BTab,
    viewComment,
  },
  props: {
    performance: {
      type: Number,
      default: null,
    },
    user: {
      type: Number,
      default: null,
    },
    subsidiaryID: {
      type: Number,
      default: null,
    },
    isHod: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
      jdID: "",
      mainTaskJD: "",
      sideTaskJD: "",
      mainTaskScoreJD: 0,
      sideTaskScoreJD: 0,
      selectedGrade: "",
      //   items: [{
      //     dbs_name: "",
      //     dbs_score: null,

      //   }],
      //   item4: [
      //     {
      //       value4: null,
      //     },
      //   ],
      mainKraNonExec: [],
      mainKraExec: [],
      sideKraNonExec: [],
      existItem2: [],
      existItem3: [],
      existItem4: [],
      gradeList: [{ text: `${this.$t('Choose Performance Grade')}`, value: "", disabled: true }],
      allGrade: [],
      roleUser: null,
      settingStatus: null,

      value1: null,
      value2: null,
      value3: null,
      value4: null,
      valueMainKra: null,
      valueSideKra: null,
      valueJD1: null,
      valueJD2: null,
      comment: null,
      isSupervisor: null,
      rating: [],
      ratingPt3: [],
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.gradeList = this.gradeList.map(x => {
        if (x.value === ""){
          return {
            ...x,
            text: this.$t('Choose Performance Grade'),
          }
        }
        return x
      })
    },
  },
  mounted() {
    this.getEmployeePerformanceScale()
    this.getPerformanceGrade()
    this.checkSupervisorStatus()
    this.getScoreRating()
    this.getScoreRatingPt3()
  },
  methods: {
    close() {
      this.$emit("close")
    },
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
    getScoreRatingPt3(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll?option=part3`).then(response => {
        this.ratingPt3 = response.data.data
      })
    },
    checkSupervisorStatus(){
      this.$axios.get(`${this.$baseUrl}/supervisor/checkSupervisory?performance_id=${this.performance}&user_id=${this.userDetails.user_id}`).then(response => {
        this.isSupervisor = response.data.data.status.is_supervisor
        // console.log("ini sv ke", this.isSupervisor)
      })
    },
    getPerformanceGrade(){
      const defautlGrade = [{ text: `${this.$t('Choose Performance Grade')}`, value: "", disabled: true }]
      this.$axios.get(`${this.$baseUrl}/performance_grades/get_by_subsidiary?subsidiary_id=${this.subsidiaryID}`).then(response => {
        this.allGrade = response.data.data
        // console.log(this.allGrade)

        this.gradeList = defautlGrade.concat(this.allGrade.map(x => (
          x.grade
        )))
        // this.allGrade.forEach(a => {
        //   this.gradeList.push(a.grade)
        // })
      })
    },
    getEmployeePerformanceScale() {
      const data = new FormData()
      data.append("user_id", this.user)
      this.$axios
        .get(
          `${this.$baseUrl}/get_employee_performance_scale?user_id=${this.user}`,
        )
        .then(response => {
          // console.log("sini ade subs id ke:", response)
          const responseData = response.data.data
          this.employeePerformanceScale = responseData
          this.roleUser = responseData.user_role
          this.settingStatus = responseData.user_kra_jd_status
          this.show = false

          if (responseData.appraiser_comment !== null){
            this.comment = responseData.appraiser_comment.comment
          }

          if (this.roleUser === 2 && this.settingStatus === 0) {
            if (responseData.job_description.length !== 0) {
              // this.jobTask = responseData.job_description
              this.jdID = this.employeePerformanceScale.job_description.id
              this.mainTaskJD = responseData.job_description.job_task
              this.mainTaskScoreJD = responseData.job_description.task_score
              this.sideTaskJD = responseData.job_description.job_subtask
              this.sideTaskScoreJD = responseData.job_description.subtask_score
              this.valueJD1 = responseData.job_description.manager_score
              this.valueJD2 = responseData.job_description.manager_subscore
            }
          }
          if (this.roleUser === 2 && this.settingStatus === 1) {
            if (responseData.main_task !== null) {
              const mainkra = responseData.main_task
              mainkra.forEach(kra => {
                this.mainKraNonExec.push({
                  mainkra_id: kra.id,
                  main_task_field: kra.main_task_field,
                  // marks: kra.manager_marks,
                  marks: kra.marks, // display self score
                  valueMainKra: kra.manager_marks, // display manager score
                })
              })
            }
          }

          if (this.roleUser === 2 && this.settingStatus === 1) {
            if (responseData.side_task !== null) {
              const sidekra = responseData.side_task
              sidekra.forEach(kraSide => {
                this.sideKraNonExec.push({
                  sidekra_id: kraSide.id,
                  side_task_summary: kraSide.side_task_summary,
                  marksSide: kraSide.marks,
                  valueSideKra: kraSide.manager_marks,
                })
              })
            }
          }

          // if (responseData.length !== 0) {
          //   for (let i = 0; i < responseData.kra.length; i += 1) {
          //     this.existItem1.push({
          //       dbs_id: responseData.kra[i].planning.dbs_id,
          //       dbs_name: responseData.kra[i].planning.dbs_name,
          //       value1: responseData.kra[i].planning.dbs_manager_score,
          //       dbs_manager_score: responseData.kra[i].planning.dbs_manager_score,
          //     })
          //   }
          // }
          if ((this.roleUser === 3 || this.roleUser === 4) && this.settingStatus === 1) {
            if (responseData.kra !== null) {
              if (responseData.kra.length !== 0) {
                this.mainKraExec = responseData.kra
              } else {
                this.mainKraExec = []
              }
            }
          }

          if (
            responseData.active_competency_core_performance !== null
          ) {
            const corePerformance = responseData.active_competency_core_performance
            corePerformance.forEach(k => {
              this.existItem2.push({
                coreId: k.core_id,
                subcoreId: k.subcore_id,
                subcore_name: this.isEnglish ? k.subcore.name_bi : k.subcore.name,
                manager_score: k.self_score, // display self score
                value2: k.manager_score,
              })
            })
          }

          if (
            responseData.active_competency_behaviour_performance !== null
          ) {
            const behaviourPerformance = responseData.active_competency_behaviour_performance
            behaviourPerformance.forEach(k => {
              this.existItem3.push({
                id: k.behaviour_id,
                behaviour_name: this.isEnglish ? k.behaviour.name_bi : k.behaviour.name,
                manager_score: k.self_score, // display self score
                value3: k.manager_score,
              })
            })
          }

          if (responseData.training_development !== null) {
            const trainingPerformance = responseData.training_development
            trainingPerformance.forEach(k => {
              this.existItem4.push({
                trainingId: k.id,
                program_name: k.program_name,
                manager_score: k.self_score,
                value4: k.manager_score,
              })
            })
          }

          this.selectedGrade = responseData.performance_grade !== null || responseData.performance_grade !== null ? responseData.performance_grade : ""
          // console.log(responseData.performance_grade)
        }).catch(() => {
          this.show = false
        })
    },
    finalScore() {
      const data = new FormData()
      data.append("user_id", this.user)

      data.append(`jd_id`, this.jdID)
      data.append(`task_score`, this.valueJD1)
      data.append(`subtask_score`, this.valueJD2)

      this.mainKraNonExec.forEach(k => {
        data.append(`main_task_id[]`, k.mainkra_id)
        data.append(`main_marks[${k.mainkra_id}]`, k.valueMainKra)
      })

      this.sideKraNonExec.forEach(k => {
        data.append(`side_task_id[]`, k.sidekra_id)
        data.append(`side_marks[${k.sidekra_id}]`, k.valueSideKra)
      })
      this.mainKraExec.forEach(k => {
        k.key_result_area.forEach(kra => {
          data.append(`result_id[]`, kra.result_id)
          data.append(`kra_rating[${kra.result_id}]`, kra.manager_rating)
        })
      })

      this.existItem2.forEach(k => {
        data.append(
          `competency_core_score[${k.coreId}][${k.subcoreId}]`,
          k.value2,
        )
      })
      this.existItem3.forEach(k => {
        data.append(`behaviour_score[${k.id}]`, k.value3)
      })
      this.existItem4.forEach(k => {
        data.append(`training_development_id[]`, k.trainingId)
        data.append(`td_score[${k.trainingId}]`, k.value4)
      })

      if (this.roles.selectedRole === 1){
        data.append(`performance_grade`, this.selectedGrade)
      }

      if (this.roles.selectedRole === 4 || this.roles.selectedRole === 3){
        data.append(`appraiser_comment`, this.comment)
      }

      // data.append(`current_role`, this.roles.selectedRole)
      data.append(`current_role`, this.roles.selectedRole)

      this.$axios
        .post(`${this.$baseUrl}/update_employee_performance_scale`, data)
        .then(() => {
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Performance successfully updated')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
            this.close()
            this.$emit('update-performance')
          }, 1000)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.eventtext {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.4px;
}
[dir] .b-form-btn-label-control.form-control > .dropdown-menu {
  padding-bottom: 3rem;
}

</style>
